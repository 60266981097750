import {
  fetchApi
} from "@/utils/axios";
import URL from "@/api/urlConfig"
import moment from "moment/moment";

// 获取酒店用户列表
export const getHotelUserInfoList = (size, page, params) => {
  const data = {
    pageNo: page,
    pageSize: size,
    projectName: params.projectName,
    enterpriseName: params.enterpriseName,
    recTimes: params.recTimes && params.recTimes.length ?
      [moment(params.recTimes[0]).format("YYYY-MM-DD"),moment(params.recTimes[1]).format("YYYY-MM-DD")] : []
  }
 return fetchApi(URL.GET_HOTEL_USERINFO_LIST, data, 'post', 'data')
}
