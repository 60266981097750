import {
    fetchApi
  } from "@/utils/axios";
  import URL from "@/api/urlConfig"
  import http from "@/utils/axios"
// 积分列表
export const pointsList = (data) => {
    return fetchApi(URL.POINTS_LIST, data, 'get', 'params', true)
}

// 获取项目名称
export const projectName = () => {
	return fetchApi(URL.BINDING_PROJECT_LIST, null)
}

// 获取用户人群
export const findAllCrowd = () => {
    return fetchApi(URL.GET_ALL_CROWD, 'null', 'get');
}

// 根据积分规则查询规则详情
export const pointsRule = (params) => {
    return fetchApi(URL.POINTS_RULE, params, 'get');
}

// 积分添加
export function pointsAdd(data) {
    return fetchApi(URL.POINTS_ADD, data, 'post', 'data')
}

// 积分详情
export const pointsInfo = (params) => {
    return fetchApi(URL.POINTS_INFO, params, 'get');
}

// 积分修改
export function pointsUpdate(data) {
    return fetchApi(URL.POINTS_ADD, data, 'put', 'data')
}

// 积分详情
export const pointsUpdateState = (params) => {
    return fetchApi(URL.POINTS_UPDATE_STATE, params, 'get');
}

// 积分模版下载
// export const pointsModel = (params) => {
//     return fetchApi(URL.POINTS_MODEL, params, 'get');
// }


// // 导出
export const pointsModel = (data) => {
    return http({
        url: URL.POINTS_MODEL,
        responseType: 'blob',
        method: 'get',
        params: data
    })
}
