<template>
  <div class="serveManage">
    <ds-header title="用户身份信息"></ds-header>
    <a-form layout="inline">
      <a-form-item label="所属企业：">
        <a-input style="width: 200px" placeholder="请输入所属企业" v-model.trim="searchForm.enterpriseName"/>
      </a-form-item>
      <a-form-item label="企业认证园区：">
        <a-select default-value="" placeholder="请选择" v-model="searchForm.projectName" style="width: 200px">
          <a-select-option :value="item.project_name" v-for="item in projectList" :key="item.project_name">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="识别身份时间：">
        <a-range-picker v-model="searchForm.recTimes" />
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
<!--        <a-button style="margin-left: 15px" @click="open">弹窗</a-button>-->
<!--        <a-button style="margin-left: 15px" @click="addPopUp">添加弹窗</a-button>-->
      </span>
    </a-form>
    <EasyRing
      :open="open"
      :ring.sync="ring"
      :loop="false"
      src="https://oss-workplace-prod.innoecos.cn/picture_prod/06ea514134810000_hotel-order-hit.WAV"
    />
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
      </a-table>
    </div>
    <div>
      <a-modal v-model="visible" :zIndex="10000" :maskClosable="false" :closable="false" :footer="null">
        <div>
          <div class="user-icon">
            <img v-if="popUpArr.length && popUpArr[0].imageUrl" :src="popUpArr[0].imageUrl" alt=""/>
            <img v-else src="https://oss-workplace-prod.innoecos.cn/picture_prod/06e9441915410000_user-icon.png" alt="">
          </div>
          <div class="popup-class">
            <a-form-model  width="800" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-form-model-item label="用户姓名">
                {{ popUpArr.length ? popUpArr[0].userName : '' }}
              </a-form-model-item>
              <a-form-model-item label="是否为一卡通用户">
                {{ popUpArr.length ? popUpArr[0].ytkUser : '' }}
              </a-form-model-item>
              <a-form-model-item label="性别">
                {{ popUpArr.length ? popUpArr[0].gender : '' }}
              </a-form-model-item>
              <a-form-model-item label="企业认证园区">
                {{ popUpArr.length ? popUpArr[0].projectName : '' }}
              </a-form-model-item>
              <a-form-model-item label="所属企业">
                {{ popUpArr.length ? popUpArr[0].enterpriseName : '' }}
              </a-form-model-item>
              <a-form-model-item label="用户身份">
                {{ popUpArr.length ? popUpArr[0].layerLabel : '' }}
              </a-form-model-item>
              <a-form-model-item label="认证点位">
                {{ popUpArr.length ? popUpArr[0].recPosition : '' }}
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 14, offset: 10 }">
                <a-button type="primary" @click="closePopup">
                  我知道了
                </a-button>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { EasyRingVueComponent as EasyRing } from 'easy-ring'
import { mapState } from "vuex";
import DSTable from "@/components/DSComponents/DSTable";
import moment from "moment";
import { projectName } from '../../../api/pointsManagement';
import { getHotelUserInfoList } from '../../../api/hotelManagement'

export default {
  name: "userIdentity",
  components: {
    DSTable,
    EasyRing
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
  },
  data() {
    return {
      open: false,
      ring: false,
      lockReconnect: false, //是否真正建立连接
      timeout: 58 * 1000, //58秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutNum: null, //断开 重连倒计时
      searchForm: {
        enterpriseName: "",
        projectName: undefined,
        recTimes: undefined
      },
      // 所有项目
      projectList: [],
      visible: false,
      labelCol: { span: 10 },
      wrapperCol: { span: 8 },
      table: {
        dataInfo: [],
        columns: [
          {
            title: "企业认证园区",
            dataIndex: "projectName",
          },
          {
            title: "所属企业",
            dataIndex: "enterpriseName",
          },
          {
            title: "认证方式",
            dataIndex: "recType",
          },
          {
            title: "认证点位",
            dataIndex: "recPosition",
          },
          {
            title: "用户姓名",
            dataIndex: 'userName'
          },
          {
            title: "性别",
            dataIndex: "gender",
          },
          {
            title: "用户身份",
            dataIndex: "layerLabel",
          },
          {
            title: "识别身份时间",
            dataIndex: "gmtCreate",
            customRender(text) {
              return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-";
            },
          }
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      popUpArr: [],
      timer: null,
    };
  },
  watch: {
    popUpArr: function(val) {
      if (val && val.length) {
        this.visible = true
        if (!this.timer) {
          this.timer = setInterval(() => {
            this.isTimeoutAndDel()
          }, 2000)
        }
      } else {
        this.visible = false
      }
    }
  },
  created() {
    this.initWebSocket();
    this.getProjectList()
    this.polling()
  },
  destroyed() {
    this.websock.close(); //离开路由之后断开websocket连接
  },
  mounted() {
    this.searchFormList();
    this.currentTime();
  },
  methods: {
    polling() {
      this.$nextTick(() => {
        this.open = true
      })
    },
    read() {
      this.ring = false
    },
    openRing() {
      this.ring = true
    },
    // 获取项目列表
    async getProjectList() {
      const res = await projectName()
      const { code, data: { items } } = res
      if (code === '200') {
        this.projectList = items
      }
    },
    // 计算是否超过一分钟，超过一分钟的删除弹框
    isTimeoutAndDel() {
      // 当前时间戳
      const currentTimeStamp = moment().unix();
      const newPopUpArr =  this.popUpArr.filter(item => {
        return currentTimeStamp - item.currentTime < 60
      })
      if (newPopUpArr.length !== this.popUpArr.length) {
        this.popUpArr = newPopUpArr
      }
      if (this.popUpArr.length === 0) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    // 处理接口参数
    searchFormList() {
      this.getHotelManagementList();
    },
    openPopUp(){
      this.visible = true;
    },
    // 添加弹窗
    addPopUp(data) {
      // 开始播放提示音
      this.openRing()
      this.popUpArr.push({...data, currentTime: moment().unix()})
    },

    // 获取列表
    async getHotelManagementList() {
      const res = await getHotelUserInfoList(this.table.pagination.pageSize, this.table.pagination.current, this.searchForm);
      if (res.code === "200") {
        this.table.pagination.total = res.data.total;
        this.table.dataInfo = res.data.records;
      }
    },
    search() {
      this.getHotelManagementList()
    },
    // 关闭弹窗
    closePopup() {
      this.popUpArr.splice(0,1)
    },
    //重置
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm.enterpriseName = "";
      this.searchForm.projectName = undefined;
      this.searchForm.recTimes = undefined
      this.searchFormList();
    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchFormList();
    },

    /**
     * weosocket
     */
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    initWebSocket() {
      let wsUrl = ''
      const userInfo = JSON.parse(localStorage.getItem('user'))
      const userId = userInfo.userId
      if (process.env.NODE_ENV === "production") {
        wsUrl = `wss://yuanqu.innoecos.cn/api/dscloud-appservice-management/socket/hotelFaceRec/${userId}`
      } else {
        wsUrl = `wss://yuanqu-sit.innoecos.cn/api/dscloud-appservice-management/socket/hotelFaceRec/${userId}`
      }

      //初始化weosocket
      const wsuri = wsUrl;
      this.websock = new WebSocket(wsuri);
      // 客户端接收服务端数据时触发
      this.websock.onmessage = this.websocketonmessage;
      // 连接建立时触发
      this.websock.onopen = this.websocketonopen;
      // 通信发生错误时触发
      this.websock.onerror = this.websocketonerror;
      // 连接关闭时触发
      this.websock.onclose = this.websocketclose;
    },
    // 连接建立时触发
    websocketonopen() {
      //开启心跳
      this.start();
      //连接建立之后执行send方法发送数据
      // let actions = {"room":"007854ce7b93476487c7ca8826d17eba","info":"1121212"};
      // this.websocketsend(JSON.stringify(actions));
    },
    // 通信发生错误时触发
    websocketonerror() {
      this.reconnect();
    },
    // 客户端接收服务端数据时触发
    websocketonmessage(e) {
      console.log(e.data);
      this.addPopUp(JSON.parse(e.data))
      this.resetSearchForm()
      this.openRing()
      //收到服务器信息，心跳重置
      this.reset();
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    // 连接关闭时触发
    websocketclose(e) {
      //关闭
      console.log("断开连接", e);
      //重连
      this.reconnect();
    },
    reconnect() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutNum && clearTimeout(that.timeoutNum);
      that.timeoutNum = setTimeout(function () {
        //新连接
        that.initWebSocket();
        that.lockReconnect = false;
      }, 5000);
    },
    reset() {
      //重置心跳
      var that = this;
      //清除时间
      clearTimeout(that.timeoutObj);
      clearTimeout(that.serverTimeoutObj);
      //重启心跳
      that.start();
    },
    start() {
      //开启心跳
      var self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.timeoutObj = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        // if (self.ws.readyState == 1) {
        if (self.ws) {
          //如果连接正常
          // self.ws.send("heartCheck"); //这里可以自己跟后端约定
        } else {
          //否则重连
          self.reconnect();
        }
        self.serverTimeoutObj = setTimeout(function () {
          //超时关闭
          self.ws.close();
        }, self.timeout);
      }, self.timeout);
    }
  },
  // 销毁定时器
  beforeDestroy() {
    if (this.formatDate) {
      clearInterval(this.formatDate); // 在Vue实例销毁前，清除时间定时器
    }
  },
};
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.footerButton {
  margin-top: 10px;
  margin-right: 10px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.inputClass {
  width: 200px;
}
.inputColor {
  color: #bdbdbd !important;
}
.user-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 30px;
  img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
  }
}
.popup-class {
  .ant-form-item {
    margin-bottom: 0;
  }
}
::v-deep .ant-calendar-picker-container {
  z-index: 1;
}
</style>
